.standings_card{
    color: white;
    width: 40vw;
    margin: 30px auto;
    background-color: white;
    display: flex;
    justify-content: space-between;
    padding: 20px 25px;
    border-radius: 30px;
    font-size: 20px;
    font-weight: bold;
    @include responsive-tablet{
        width: 80vw;
    }
    span{
        margin-right: 10px;
        padding: 5px 10px;
        border-radius: 50%;
        background-color: white;
    }
}