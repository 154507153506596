.home {
    .container {
        flex-direction: column;
        display: flex;
        min-height: 100vh;

        nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;
            .logo {
                img {
                    width: 100px;

                    @include responsive-mobile {
                        width: 80px;
                    }

                    @include responsive-mobile-small {
                        width: 60px;
                    }
                }
            }

            h1 {
                color: white;
                font-size: 40px;
                font-weight: bold;

                @include responsive-mobile {
                    font-size: 25px;
                }
            }

            .PST-logo {
                img {
                    width: 80px;

                    @include responsive-mobile {
                        width: 60px;
                    }

                    @include responsive-mobile-small {
                        width: 40px;
                    }
                }
            }
        }

        .levels {
            flex-wrap: wrap;
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .level0 {
                padding: 30px;
                background-color: rgb(1, 1, 66);
                height: 350px;
                width: 450px;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @include responsive-tablet{
                    margin: 20px 0;
                }
                .title {
                    img {
                        width: 100px;
                        margin: 10px 0 0 0;
                    }

                    h2 {
                        color: white;
                    }
                }

                .groups {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    a {
                        font-weight: bold;
                        transition: ease-in .4s;
                        padding: 10px 30px;
                        margin: 20px 0;
                        border-radius: 10px;
                        background-color: rgb(2, 3, 86);
                        color: white;

                        &:hover {
                            background-color: #1A5CFD;
                        }
                    }
                }
            }

            .level1 {
                padding: 30px;
                background-color: rgb(1, 1, 66);
                height: 350px;
                width: 450px;
                border-radius: 20px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                @include responsive-tablet{
                    margin: 20px 0;
                }
                .title {

                    // height: 100px;
                    img {
                        width: 100px;
                        margin: 10px 0 0 0;
                    }

                    h2 {
                        color: white;
                    }
                }

                .groups {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    a {
                        font-weight: bold;
                        transition: ease-in .4s;
                        padding: 10px 30px;
                        margin: 20px 0;
                        border-radius: 10px;
                        background-color: rgb(2, 3, 86);
                        color: white;

                        &:hover {
                            background-color: #1A5CFD;
                        }
                    }
                }
            }
        }
    }
}