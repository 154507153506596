* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Rubik', sans-serif;
    background-color: rgb(2,3,86);
  }

  a{
    text-decoration: none;
    display: block;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 3px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(31, 31, 31);
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(2,3,86);
  }
  
  .container{
    width: 90vw;
    margin: 0 auto;
  }