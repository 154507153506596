.score {
    .container {
        nav {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0;

            .logo {
                img {
                    width: 100px;

                    @include responsive-mobile {
                        width: 80px;
                    }

                    @include responsive-mobile-small {
                        width: 60px;
                    }
                }
            }

            h1 {
                color: white;
                font-size: 40px;
                font-weight: bold;

                @include responsive-mobile {
                    font-size: 25px;
                }
            }

            .PST-logo {
                img {
                    width: 80px;

                    @include responsive-mobile {
                        width: 60px;
                    }

                    @include responsive-mobile-small {
                        width: 40px;
                    }
                }
            }
        }
    }
}