@mixin responsive-mobile-small {
    @media (max-width: 380px) {
      @content;
    }
  }
  
  @mixin responsive-mobile {
    @media (max-width: 576px) {
      @content;
    }
  }
  
  @mixin responsive-tablet {
    @media (max-width: 767px) {
      @content;
    }
  }
  
  @mixin responsive-desktop {
    @media (max-width: 992px) {
      @content;
    }
  }
  